import * as R from 'ramda'

import {
  alphabeticalSort,
  removeDuplicate,
  removeDuplicateContentfulObject,
  removeDuplicateObject,
} from 'helpers/utils'

export default function useInitiativesFilterData(data) {
  const getTagsObject = (field, label) =>
    // eslint-disable-next-line fp/no-mutating-methods
    R.sort(
      (a, b) => a.name.localeCompare(b.name),
      R.flatten(R.map(el => R.map(e => e, R.pathOr('', [label], el)), field)),
    )
  const {allContentfulInitiativePillar} = data

  const allContentfulInitiative = R.pathOr(
    null,
    ['allContentfulInitiative', 'edges'],
    data,
  )

  const allCountriesInitiatives = R.pathOr(
    null,
    ['allCountriesInitiatives', 'edges'],
    data,
  )

  const {filterByCountryDefaultFilter} = R.pathOr(
    null,
    ['allContentfulInitiatives', 'nodes', 0],
    data,
  )

  const countriesTagsArray = R.map(
    ({node}) => node && node.countriesOfDestination,
    allCountriesInitiatives,
  )

  const flattenCountriesTagsArray = removeDuplicateContentfulObject(
    R.flatten(countriesTagsArray),
  )

  const filterDummyContent = flattenCountriesTagsArray.filter(
    item =>
      item !== null && !item.contentful_id.includes('3yyvXqMvJsg3LxOEY9TJV4'),
  )

  const sortedCountriesTagsArray = removeDuplicate(
    R.map(el => el?.name, filterDummyContent),
  )

  const contentfulCountry = R.pathOr(null, ['contentfulCountry'], data)

  const allInitiatives =
    !R.isEmpty(allContentfulInitiative) &&
    R.map(({node}) => node, allContentfulInitiative)

  const countryInitiative = R.pathOr(null, ['initiative'], contentfulCountry)

  const allContentfulInitiativeAllCountries = R.pathOr(
    null,
    ['allContentfulInitiativeAllCountries', 'nodes'],
    data,
  )

  const allPillarsItems = removeDuplicateContentfulObject(
    R.flatten(R.map(el => el.pillars, allContentfulInitiativeAllCountries)),
  )

  const allCountriesItems = removeDuplicateContentfulObject(
    R.flatten(
      R.map(
        el => el.countriesOfDestination,
        allContentfulInitiativeAllCountries,
      ),
    ),
  )

  const countryPillarsTags =
    countryInitiative && getTagsObject(countryInitiative, 'pillars')

  const countriesTagsObjects =
    filterDummyContent &&
    R.map(
      item => ({
        id: item?.contentful_id,
        url: item?.name,
        name: item?.name,
      }),
      filterDummyContent,
    )

  const countryPillarsTagsObjects =
    countryInitiative &&
    removeDuplicateObject(
      R.map(
        item => ({
          id: item.contentful_id,
          url: item.name,
          name: item.name,
        }),
        countryPillarsTags,
      ),
    )

  const allCountriesToggleTag = [
    {
      id: 'all-countries',
      url: 'All countries',
      name: filterByCountryDefaultFilter,
    },
  ]
  const [allCountriesToggleTagObject] = allCountriesToggleTag

  const allTagsObjects = R.concat(
    allCountriesToggleTag,
    R.map(
      item => ({
        id: item?.contentful_id,
        url: item?.name,
        name: item?.name,
      }),
      R.concat(allPillarsItems, allCountriesItems),
    ),
  )

  const countryInitiativesIds =
    allInitiatives &&
    R.map(({node}) => node.contentful_id, allContentfulInitiative)

  const pillarsArray =
    countryInitiative &&
    R.map(el => R.pathOr(null, ['pillars'], el), countryInitiative)

  const flattenPillarsArray = pillarsArray && R.flatten(pillarsArray)

  const newPillarsArray =
    flattenPillarsArray &&
    R.map(el => R.pathOr(null, ['name'], el), flattenPillarsArray)

  const pillarsSortedTagsArray =
    newPillarsArray && alphabeticalSort(newPillarsArray)

  return {
    allContentfulInitiative,
    allContentfulInitiativePillar,
    contentfulCountry,
    countryInitiativesIds,
    pillarsSortedTagsArray,
    sortedCountriesTagsArray,
    allTagsObjects,
    countryPillarsTagsObjects,
    countriesTagsObjects,
    allCountriesToggleTagObject,
  }
}
