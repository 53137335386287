import {countriesByLocales} from '../../keyrus/static/countries/countriesByLocales'
import {countriesByLocalesKls} from '../../keyrus-life-science/static/countriesByLocalesKls'
import {countriesByLocalesKm} from '../../keyrus-management/static/countriesByLocalesKm'

export const getWebsiteCountriesByLocales = () => {
  if (process.env.GATSBY_WEBSITE === 'keyrus') {
    return countriesByLocales
  }
  if (process.env.GATSBY_WEBSITE === 'kls') {
    return countriesByLocalesKls
  }
  if (process.env.GATSBY_WEBSITE === 'keyrus-management') {
    return countriesByLocalesKm
  }

  return false
}
