import * as R from 'ramda'
import {useEffect, useState} from 'react'

import {
  countryItemsQuery,
  itemsQuery,
  itemsQueryIds,
} from 'helpers/filters/initiatives/itemsQuery'
import {removeDuplicate} from 'helpers/utils'

import useIsBrowser from 'hooks/useIsBrowser'

const url = `https://graphql.contentful.com/content/v1/spaces/${process.env.GATSBY_CONTENTFUL_SPACE_ID}/?access_token=${process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN}`

const request = graphqlQuery => ({
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    query: graphqlQuery,
  }),
})

const retrieveItems = async (countryItemsIds, hook, locale, limit = 50) => {
  if (countryItemsIds) {
    await fetch(
      url,
      request(`
        {
          initiativeCollection(
          limit: ${JSON.stringify(limit)},
          locale: ${JSON.stringify(locale)}, 
          where: {sys: {id_in: ${JSON.stringify(countryItemsIds)}}}, 
          order:  publicationDate_DESC) {
            ${itemsQuery}
          }
        } 
    `),
    )
      .then(response => response.json())
      .then(response => hook(response.data.initiativeCollection.items))
  }
}

const retrieveCountriesItems = async (
  countryItemsIds,
  hook,
  locale,
  hasSkip,
) => {
  if (countryItemsIds) {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
            {
              initiativeCollection(
                locale: ${JSON.stringify(locale)},
                where: {sys: {id_in: ${JSON.stringify(countryItemsIds)}}},
                order: publicationDate_DESC
                ${hasSkip ? 'skip: 2' : ''}
              ) {
                ${countryItemsQuery}
              }
            }
          `,
      }),
    })

    try {
      await response
        .json()
        .then(res => hook(res.data.initiativeCollection.items))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error parsing JSON:', error)
    }
  }
}

const retrieveDefaultItems = async (countryItemsIds, hook, locale, hasSkip) =>
  retrieveCountriesItems(countryItemsIds, hook, locale, hasSkip)

const contentfulGraphQlFiltering = async (
  urlPrefix,
  locale,
  tags,
  hook,
  limit,
  countryItemsIds,
  allCountriesSelected,
) => {
  const newTags = R.map(node => node.id, tags)

  if (allCountriesSelected) {
    await fetch(
      url,
      request(`
        {
          initiativeCollection(
          limit: 50,
          where: { countryOfOrigin: { sys: { id_not: "3yyvXqMvJsg3LxOEY9TJV4" } } }
          order:  publicationDate_DESC) {
            ${itemsQuery}
          }
        } 
    `),
    )
      .then(response => response.json())
      .then(response => hook(response.data.initiativeCollection.items))
  } else {
    await fetch(
      url,
      request(`
        {
          initiativePillarCollection(
          where: {sys: {id_in: ${JSON.stringify(newTags)}}},
          limit: ${limit}) {
            ${itemsQueryIds}
          }
          countryCollection(
          where: {sys: {id_in: ${JSON.stringify(newTags)}}},
          limit: ${limit}) {
            ${itemsQueryIds}
          }
        } 
      `),
    )
      .then(response => response.json())
      .then(response => {
        const initiativePillarCollectionItems = R.map(
          el => el.linkedFrom.initiativeCollection.items,
          response.data.initiativePillarCollection.items,
        )
        const countryCollectionItems = R.map(
          el => el.linkedFrom.initiativeCollection.items,
          response.data.countryCollection.items,
        )

        const initiativePillarCollectionItemsIds = R.map(
          el => el.sys.id,
          R.flatten([...initiativePillarCollectionItems]),
        )
        const countryCollectionItemsIds = R.map(
          el => el.sys.id,
          R.flatten([...countryCollectionItems]),
        )

        const filteredinitiativePillarCollectionItemsIdsByCountry = R.filter(
          value =>
            removeDuplicate(initiativePillarCollectionItemsIds).includes(value),
          countryItemsIds,
        )

        const allCollectionItemsIds = R.flatten([
          ...countryCollectionItemsIds,
          ...filteredinitiativePillarCollectionItemsIdsByCountry,
        ])

        return (
          countryItemsIds && retrieveItems(allCollectionItemsIds, hook, locale)
        )
      })
  }
}

const useFilter = (
  pageContext,
  countryData,
  currentTag,
  countryItemsIds,
  allCountriesSelected,
  hasSkip,
) => {
  const [itemsToRender, setItemsToRender] = useState([])
  const {nodeLocale} = pageContext
  const {urlPrefix} = countryData

  const noFilterSelected =
    useIsBrowser &&
    (window.location.hash === '' ||
      window.location.hash === '#/' ||
      currentTag.length === 0)

  useEffect(() => {
    if (!noFilterSelected) {
      contentfulGraphQlFiltering(
        urlPrefix,
        nodeLocale,
        currentTag,
        setItemsToRender,
        '12',
        countryItemsIds,
        allCountriesSelected,
      )
    } else {
      retrieveDefaultItems(
        countryItemsIds,
        setItemsToRender,
        nodeLocale,
        hasSkip,
      )
    }
  }, [useIsBrowser && window.location.hash && currentTag])

  return itemsToRender
}

export default useFilter
