import * as R from 'ramda'
import {Link} from 'gatsby'
import React, {useRef, useState} from 'react'

import {blogListPropTypes} from 'helpers/propTypes'
import {getCommonItems} from 'helpers/utils'
import {getWebsiteCountriesByLocales} from 'helpers/getWebsiteCountriesByLocales'
import Filter from 'components/UI/Filter'
import FiltersContainer from 'components/UI/FiltersContainer'
import HashTagButtons from 'components/UI/HashTagButtons'
import InfiniteScrollObserver from 'components/UI/InfiniteScrollObserver'
import LoadMore from 'components/UI/LoadMore'
import Section from 'components/UI/Section'
import useFilter from 'hooks/useFilterInitiatives'
import useHashConverter from 'hooks/useHashConverter'
import useHashReader from 'hooks/useHashReader'
import useIsMobile from 'hooks/useIsMobile'
import useObserver from 'hooks/useObserver'
import useTagsObject from 'hooks/useTagsObject'

import useStyles from './styles'

const BlogList = ({
  countryInsightsIds,
  typesTags,
  searchPlaceholder,
  filterByTypeLabel,
  filterByCountryLabel,
  filterByCountryDefaultFilter,
  filterSeeMoreLabel,
  filterSeeLessLabel,
  pageContext,
  countryData,
  location,
  filteredCountriesTagsArray,
  allTagsObjects,
  hasSkip,
}) => {
  const classes = useStyles()
  const isMobile = useIsMobile()
  const mapIndexed = R.addIndex(R.map)
  const loadRef = useRef()
  const [limit, setLimit] = useState(10)
  const [loadMore, setLoadMore] = useState(false)
  const [hashConditions] = useHashReader(location)
  const {hashIsEmpty} = hashConditions
  const [selectedTags] = useHashConverter()
  const [selectedTagsObjects] = useTagsObject(selectedTags, allTagsObjects)

  const {allCountriesSelected} = hashConditions

  const itemsToRender = useFilter(
    pageContext,
    countryData,
    selectedTagsObjects,
    countryInsightsIds,
    allCountriesSelected,
    hasSkip,
  )

  const condItemsToRender = hashIsEmpty
    ? R.slice(0, limit, itemsToRender)
    : itemsToRender

  const hasMoreItems = limit <= R.length(itemsToRender)

  const loadMoreItems = () => {
    if (hasMoreItems) {
      setLoadMore(true)

      setTimeout(() => {
        setLimit(val => val + 10)
        setLoadMore(false)
      }, 1000)
    }
  }

  const [infiniteScroll, setInfiniteScroll] = useObserver(
    loadRef,
    loadMoreItems,
  )

  const handleLoadMore = () => {
    setInfiniteScroll(true)
    loadMoreItems()
  }

  const returnBlogList = () =>
    mapIndexed((node, index) => {
      const {
        image,
        pillarsCollection,
        publicationDate,
        metaDescription,
        title,
        countriesOfDestinationCollection,
        countryOfOrigin,
      } = node
      const pillarsCollectionItems = pillarsCollection.items
      const eulaSlug = node.slug === 'eula'

      const country3CollectionItems =
        countriesOfDestinationCollection &&
        R.pathOr(null, ['items'], countriesOfDestinationCollection)

      const filteredKeyrusWebsiteCountries =
        country3CollectionItems &&
        country3CollectionItems.length > 0 &&
        getCommonItems(
          country3CollectionItems,
          getWebsiteCountriesByLocales(),
          'technicalName',
        )

      const renderNavigateTo = () => {
        const initiativesPrefix = '../../../../../'
        const initiativesPath = '/about-us/committed-keyrus/initiatives/'

        if (eulaSlug) {
          return '../eula'
        }
        if (
          country3CollectionItems &&
          country3CollectionItems.length > 0 &&
          country3CollectionItems.find(
            country =>
              country.countryCode === countryData.countryCode &&
              country.navLocale === countryData.navLocale,
          )
        ) {
          if (countryData.countryCode === 'worldwide') {
            return `${initiativesPrefix}${countryData.countryCode}${initiativesPath}${node.slug}`
          }

          return `${initiativesPrefix}${countryData.countryCode}/${countryData.navLocale}${initiativesPath}${node.slug}`
        }

        if (filteredKeyrusWebsiteCountries) {
          if (filteredKeyrusWebsiteCountries[0]?.countryCode === 'worldwide') {
            return `${initiativesPrefix}${filteredKeyrusWebsiteCountries[0].countryCode}${initiativesPath}${node.slug}`
          }

          return `${initiativesPrefix}${filteredKeyrusWebsiteCountries[0]?.countryCode}/${country3CollectionItems[0].navLocale}${initiativesPath}${node.slug}`
        }

        return process.env.GATSBY_WEBSITE === 'keyrus-fondation'
          ? `../news/${node.slug}`
          : `../initiatives/${node.slug}`
      }

      const renderDate = () =>
        new Date(publicationDate).toLocaleString('fr-FR', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        })

      return (
        <Link
          to={renderNavigateTo()}
          className={classes.blogContainer}
          key={index}
        >
          <div className={classes.card}>
            {image && <img src={image.url} alt={image.url} />}
          </div>
          <div className={classes.blogContent}>
            <HashTagButtons
              disabled
              countries={countryOfOrigin && [countryOfOrigin]}
              services={pillarsCollectionItems}
            />
            <h2 className={classes.metaTitle}>{title && title}</h2>
            <p className={classes.date}>{renderDate()}</p>
            <p className={classes.metaDescription}>
              {metaDescription && metaDescription}
            </p>
          </div>
        </Link>
      )
    }, condItemsToRender)

  return (
    <>
      <Section hasPaddingTop={false}>
        <div className={classes.root}>
          <div className={classes.blogListRoot}>
            {returnBlogList()}
            {hashIsEmpty && hasMoreItems && (
              <LoadMore
                label={filterSeeMoreLabel}
                loadMore={loadMore}
                hasMoreItems={hasMoreItems}
                infiniteScroll={infiniteScroll}
                handleLoadMore={handleLoadMore}
              />
            )}
          </div>
          {!isMobile ? (
            <div className={classes.filtersContainer}>
              <FiltersContainer searchPlaceholder={searchPlaceholder}>
                <Filter
                  array={typesTags}
                  filterLabel={filterByTypeLabel}
                  filterSeeMoreLabel={filterSeeMoreLabel}
                  filterSeeLessLabl={filterSeeLessLabel}
                  hasObjectsTags
                />
                {process.env.GATSBY_WEBSITE === 'keyrus' && (
                  <Filter
                    array={filteredCountriesTagsArray}
                    filterLabel={filterByCountryLabel}
                    filterSeeMoreLabel={filterSeeMoreLabel}
                    filterSeeLessLabel={filterSeeLessLabel}
                    filterAllLabel={filterByCountryDefaultFilter}
                    hasAllFilter
                    hasObjectsTags
                  />
                )}
              </FiltersContainer>
            </div>
          ) : null}
        </div>
      </Section>
      <InfiniteScrollObserver show={infiniteScroll} Ref={loadRef} />
    </>
  )
}

BlogList.propTypes = blogListPropTypes

BlogList.defaultProps = {}

export default BlogList
