export const itemsQuery = `
items {
  title
  slug
  metaDescription
  publicationDate
  image {
    url(transform: {width: 800, height: 450, resizeStrategy: FILL, resizeFocus: CENTER, format: WEBP, quality: 100})
  }
  pillarsCollection {
    items {
      name
    }
  }
  countriesOfDestinationCollection {
    items {
      technicalName
      navLocale
      countryCode
      name
    }
  }
  countryOfOrigin {
    name
  }
}
`

export const countryItemsQuery = `
items {
  title
  slug
  metaDescription
  publicationDate
  image {
    url(transform: {width: 800, height: 450, resizeStrategy: FILL, resizeFocus: CENTER, format: WEBP, quality: 100})
  }
  pillarsCollection {
    items {
      name
    }
  }
  countryOfOrigin {
    name
  }
}
`

export const itemsQueryIds = `
  items {
    name
    sys {
      id
    }
    linkedFrom {
      initiativeCollection {
        items {
          sys {
            id
          }
        }
      }
    }
  }
`

export const itemsSysId = `
items {
  sys {
    id
  }
}
`
