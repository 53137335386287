import * as R from 'ramda'
import {useEffect, useState} from 'react'

export default function useTagsObject(selectedTags, allTagsObjects) {
  const [state, setState] = useState([])

  const getSelectedTagsObjects =
    selectedTags &&
    R.filter(e => R.includes(e.url, selectedTags), allTagsObjects)

  useEffect(() => {
    setState(getSelectedTagsObjects)
  }, [selectedTags])

  return [state, setState]
}
